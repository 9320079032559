document.addEventListener('DOMContentLoaded', () => {
  function onFacebookMessengerClicked() {
    window.gtag('event', 'contact', {
      contact_tool: 'facebook',
      link_domain: 'm.me',
    });
    window.rdt('track', 'Lead');
  }

  const facebookMessengerLinks = document.querySelectorAll(
    '#facebook-messenger'
  );

  facebookMessengerLinks.forEach(l =>
    l.addEventListener('click', onFacebookMessengerClicked)
  );

  function onBookACallClicked() {
    window.gtag('event', 'contact', {
      contact_tool: 'calendly',
      link_domain: 'calendly.com',
    });
    window.rdt('track', 'Lead');
  }

  const bookACallLinks = document.querySelectorAll('#book-a-call');

  bookACallLinks.forEach(l => l.addEventListener('click', onBookACallClicked));

  function onSetupViaLiveChatClicked() {
    window.gtag('event', 'contact', {
      contact_tool: 'live chat',
      link_domain: 'tawk.to',
    });
    window.rdt('track', 'Lead');
  }

  const setupViaLiveChatLinks = document.querySelectorAll(
    '#set-up-via-live-chat'
  );

  setupViaLiveChatLinks.forEach(l =>
    l.addEventListener('click', onSetupViaLiveChatClicked)
  );

  function onViewDemoClicked() {
    window.gtag('event', 'demo');
    window.rdt('track', 'Custom', { customEventName: 'demo' });
  }

  const viewDemoLinks = document.querySelectorAll('#view-demo');

  viewDemoLinks.forEach(l => l.addEventListener('click', onViewDemoClicked));
});
